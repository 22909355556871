.preview :global(.konvajs-content) {
    outline: 1px solid black;
}

.dropZone {
    height: 150px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    border: 1px solid black;
}

.dropZoneText {
    width: 70%;
    text-align: center;
}

.content {
    flex: 1 1 auto;
}

.controls {
    flex: 0 0 230px;
    padding: 0 15px;
}