tags.content > span {
    min-height: 370px;
}

.previewWrapper {
    height: 100%;
    width: 100%;
    display: flex;
}

.preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.blankImage {
    width: 100%;
    height: 100%;
    background: #d4cdd4;
    object-fit: contain;
}

.previewImg {
    width: 100%;
    border: 1px solid #ddd;
    object-fit: contain;
}