.bold {
    font-weight: bold;
}

.printButton {
    border-color: #999999;
}
.printButton:hover {
    border-color: #999999;
    background: #999999;
}

.printButton i {
    color: #999999;
}

.printButton:hover i {
    color: white;
}

.editAction i {
    display: none;
}

.editAction.enabled i {
    display: inline;
}

.editAction.enabled:hover {
    color: #1659c7;
    cursor: pointer;
}

/* Product title formatting */

.editable a[href]:hover {
    color: #1659c7;
}

.editable a:not([href]):hover {
    cursor:text;
}

.editable :global(.edit-icon), .editable :global(.actions) {
    border: none;
    line-height: 1rem;
}

.editable :global(.actions) button {
    line-height: 1rem;
    box-shadow: none;
}

.editable :global(.actions) button i {
    color: #6c757d;
}

.editable :global(.actions) button:hover i {
    color: #1659c7;
}

.editable:hover :global(.edit-icon), .editable:hover :global(.actions) {
    display: inline;
}

/* Table item placement and sizes */

.keyCell, .previewCell, .valueCell {
    vertical-align: top;
}

.keyCell {
    padding-left: 15px;
    vertical-align: middle;
}

.valueCell {
    padding-right: 15px;
    vertical-align: middle;
}

.highlight:hover td {
    background-color: #e5e5e5;
}

.header > td {
    padding-top: 10px;
    content: "";
}

.footer > td {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
    content: "";
}

.paddingLevel1 {
    padding-left: 0px;
}

.paddingLevel2 {
    padding-left: 15px;
}

.paddingLevel3 {
    padding-left: 30px;
}

.paddingLevel4 {
    padding-left: 45px;
}

.labelLevel1, .labelLevel2 {
    color: #495057;
    font-weight: 500;
}

.labelLevel3, .labelLevel4 {
    color: #6c757d;
}

.labelLevel1 {
    font-size: 1.25rem;
    line-height: 1.5rem
}

.labelLevel2 {
    font-size: 1.1rem;
    line-height: 1.35rem;
}

.labelLevel3 {
    font-size: 0.875rem;
    line-height: 1.125rem;
}

.labelLevel4 {
    font-size: 0.875rem;
    line-height: 1rem;
}
.labelLevel1, .labelLevel2, .labelLevel3 {
    font-weight: 600;
}

.labelLevel1, .labelLevel2, .labelLevel3, .labelLevel4 {
    font-family: 'Courier New', Courier, monospace;
    color: #6c757d;
}

.priceMain, .priceLevel1, .priceLevel2, .priceLevel3, .priceLevel4 {
    font-family: 'Courier New', Courier, monospace;
}

.priceMain {
    margin: -0.75rem 0;
    letter-spacing: -1px;
    font-weight: 700;
    font-size: 2rem;
}

.priceLevel1 {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.5rem;
}

.priceLevel2 {
    font-weight: 700;
    font-size: 1.1rem;
    line-height: 1.5rem;
}

.priceLevel3 {
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.priceLevel4 {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.preserveNewLines {
    white-space: pre-wrap;
}