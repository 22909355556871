body {
    overflow: auto;
}

.badge-light {
    color: #212529;
}

.top-right-container{
    position:absolute;
    top:20px;
    right:20px;
}

.btn-group-vertical>.btn.active, .btn-group-vertical>.btn:active, .btn-group-vertical>.btn:focus, .btn-group-vertical>.btn:hover, .btn-group>.btn.active, .btn-group>.btn:active, .btn-group>.btn:focus, .btn-group>.btn:hover{
    z-index: 0;
}

.tooltip-inner{
    max-width: fit-content!important;
}

.server-side-search-label{
    position: absolute;
    right: 0;
    display: flex;
    margin: 0 20px 0 0;
    align-items: center;
    gap: 5px;
    z-index: 5;
}