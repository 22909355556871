.carousel {
    max-width: 700px;
    animation: fadeIn .4s ease-in;
}

.carousel :global(.carousel-arrow) {
    position: absolute;
    top: 50%;
    border: 1px solid #3f80ea;
    font-size: 1.5rem;
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    transform: translate(0, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 0.2s ease-out, border-color 0.2s ease-out;
}

.carousel :global(.carousel-arrow.disabled) {
    color: rgb(200, 200, 200);
    border-color: rgb(200, 200, 200);
    cursor: default;
}

.carousel :global(.carousel-arrow--left) {
    left: -50px;
}

.carousel :global(.carousel-arrow--right) {
    right: -50px;
}

@media only screen and (min-width: 992px) {
    .carousel :global(.carousel-arrow--left) {
        left: -60px;
    }

    .carousel :global(.carousel-arrow--right) {
        right: -60px;
    }
}

.pageContainer {
    height: 40vw;
    max-height: 350px;
}

.pageWrapper {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
}

.page {
    border: 1px solid black;
    position: relative;
}

.page:hover {
    cursor: pointer;
    /* border-width: 2px; */
}

.pageLabel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    font-size: 1.25rem;
}

.pageTitle {
    position: absolute;
    bottom: -2rem;
    right: 0;
    overflow: visible;
    text-align: center;
    left: 50%;
    width: 300%;
    transform: translateX(-50%);
    font-size: 0.875rem;
    white-space: nowrap;
}

.page:hover .pageLabel {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to   { opacity: 1; }
}
