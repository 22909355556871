.product .editIcon, .product .deleteIcon {
    display: none;
    color: #1659c7;
}

.product:hover .editIcon, .product:hover .deleteIcon {
    display: inline;
}

.deleteIcon {
    cursor: pointer;
}